import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import ScribiProvider, { Scribi } from "./utilities/api/store/store";
import { UserContextProvider } from "./utilities/api/store/UserContext";
import { SocialContextProvider } from "./utilities/api/store/SocialContext";
import { CatalogContextProvider } from "./utilities/api/store/CatalogContext";
import { ProjectContextProvider } from "./utilities/api/store/ProjectContext";
import { TimerContextProvider } from "./utilities/api/store/TimerContext";
import { DocumentContextProvider } from "./utilities/api/store/DocumentContext";
import { ModalContextProvider } from "./utilities/api/store/ModalContext";
import { MenuContextProvider } from "./utilities/api/store/MenuContext";
import { combineProviders } from "./utilities/api/store/combineProviders";

const CombinedProvider = combineProviders(
  UserContextProvider,
  SocialContextProvider,
  CatalogContextProvider,
  ProjectContextProvider,
  TimerContextProvider,
  DocumentContextProvider,
  ModalContextProvider,
  MenuContextProvider
);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <CombinedProvider>
      <ScribiProvider>
      <App />
      </ScribiProvider>
    </CombinedProvider>
  </BrowserRouter>
);
