/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { auth, db } from "../../firestore/cloud-configs";
import { doc, onSnapshot } from "firebase/firestore";

const UserContext = React.createContext();

const UserContextProvider = ({ children }) => {
    const [user, setUser] = React.useState(null);

  
     useEffect(() => {
      const unsubscribeAuth = auth.onAuthStateChanged(
        (data) => {
          setUser(data);
        },
        (error) => {
          console.error("Auth state change error:", error);
        }
      );

      return () => {
        console.log("Cleaning up auth listener");
        unsubscribeAuth();
      };
    }, []);
  
    useEffect(() => {
      if (user) {
        const userRef = doc(db, "users", user.uid);
        const unsubscribeUser = onSnapshot(
          userRef,
          (snapshot) => {
            setUser(currentUser => ({ ...currentUser, ...snapshot.data() }));
          },
          (error) => {
            console.error(`Error fetching user data: ${error}`);
          }
        );
  
        return () => unsubscribeUser();
      }
    }, [user?.uid]);
  
    const contextValue = React.useMemo(() => ({ user, setUser }), [user]);
  
    return (
      <UserContext.Provider value={contextValue}>
        {children}
      </UserContext.Provider>
    );
  };
  

export { UserContext, UserContextProvider };