import React, { useContext, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { ModalContext } from '../utilities/api/store/ModalContext';
import { HrDivider } from '../Scribi-Components/ScribiComponents';

const slideIn = keyframes`
  from {
    transform: translate(100%, -50%);
  }
  to {
    transform: translate(-50%, -50%);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const NotificationOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  width: 100vw;
  height: 100vh;
  pointer-events: none; 
`;

const NotificationContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 10px;
  top: 20px; 
  right: 20px; 
  transform: translate(-50%, 0); 
  z-index: 501; 
  animation: ${slideIn} 0.5s forwards, ${fadeOut} 0.5s forwards 3s; 
  pointer-events: auto; 
  background-color: ${({theme}) => theme.primary};
  border-radius: 5px;
  border: 1px solid ${({theme}) => theme.secondary};
    padding: 10px;
`;

// Usage in a component
const Notification = () => {
    const {notificationModal, setNotificationModal} = useContext(ModalContext);

    useEffect(() => {
        if(notificationModal){
        let timeoutId;
        if (notificationModal.visible) {
            // Set a timeout to hide the notification after 3 seconds
            timeoutId = setTimeout(() => {
                // Start the fade-out animation
                // Then, after the animation, hide the notification
                setNotificationModal(null);
            }, 3000); // 3000ms = 3s
        }
        
        return () => {
            // Clear the timeout if the component unmounts
            // or if the modal visibility changes
            clearTimeout(timeoutId);
        };}
    }, [notificationModal, setNotificationModal]);

    return notificationModal && (
        <NotificationOverlay>
            <NotificationContainer>
                <label>{notificationModal.title}</label>
                <HrDivider />
                <p>{notificationModal.message}</p>
            </NotificationContainer>
        </NotificationOverlay>
    );
};


export default Notification;