import React from "react";

const TimerContext = React.createContext();

const TimerContextProvider = ({ children }) => {
    const [timer, setTimer] = React.useState(null);
    const [sprintStats, setSprintStats] = React.useState(null);
    const [groupTimers, setGroupTimers] = React.useState(null);
    
    return (
        <TimerContext.Provider
            value={{
                timer,
                setTimer,
                sprintStats,
                setSprintStats,
                groupTimers,
                setGroupTimers,
            }}
        >
            {children}
        </TimerContext.Provider>
    );
}

export { TimerContext, TimerContextProvider };