/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { db, RTDatabase } from "../../firestore/cloud-configs";
import {
  onSnapshot,
  collection,
  doc,
  orderBy,
  query,
  where,
  or,
} from "firebase/firestore";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useTheme } from "styled-components";
import ScribiRestService from "../../firestore/ScribiRestService";
import {
  batchDeleteScribiDocuments,
  createScribiDocument,
  deleteScribiDocument,
  updateScribiDocument,
} from "../../firestore/db";
import { v4 as uuid } from "uuid";
import { onValue, ref, set, update } from "firebase/database";
import { useDndContext } from "@dnd-kit/core";
import { UserContext } from "./UserContext";
import { CatalogContext } from "./CatalogContext";
import { ModalContext } from "./ModalContext";

export const Scribi = React.createContext();

const ScribiProvider = ({ children }) => {

  const {user, setUser} = React.useContext(UserContext);

  const {
    pens,
    setPens,
    worlds,
    setWorlds,
    series,
    setSeries,
    books,
    setBooks,
} = React.useContext(CatalogContext);

const {notificationModal, setNotificationModal} = React.useContext(ModalContext);

  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [notes, setNotes] = useState([]);
  const [convos, setConvos] = useState([]);
  const [elements, setElements] = useState([]);
  const [calendarView, setCalendarView] = useState("month");
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [today, setToday] = useState(new Date());
  const [eventSeries, setEventSeries] = useState([]);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
    menu: null,
  });
  const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [sidebar, setSidebar] = useState({ visible: false, width: 1 });
  const [projectNavState, setProjectNavState] = useState({});
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [project, setProject] = useState({});
  const [flattenedProjectContents, setFlattenedProjectContents] = useState([]);
  const [sprintStats, setSprintStats] = useState(null);
  const [joinedSprintTimer, setJoinedSprintTimer] = useState(null);
  const [groupSprints, setGroupSprints] = useState([]);
  const [timer, setTimer] = useState(null);
  const [sprintId, setSprintId] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [dragged, setDragged] = useState(null);
  const [draggedOver, setDraggedOver] = useState(null);
  const [dragItem, setDragItem] = useState(null);
  const [currentDoc, setCurrentDoc] = React.useState(null);
  const [currentPartHeading, setCurrentPartHeading] = useState("");
  const [currentChapterHeading, setCurrentChapterHeading] = useState("");
  const [currentDocumentHeadings, setCurrentDocumentHeadings] = useState([""]);
  const [currentDocParent, setCurrentDocParent] = useState(null);
  const [currentDocGrandparent, setCurrentDocGrandparent] = useState(null);
  const [note, setNote] = useState(null);

  useEffect(() => {
    if (currentDoc) {
      let headings = [];
      let parent = null;
      let grandparent = null;

      const setHeadings = (
        contents,
        id,
        parentCandidate = null,
        grandparentCandidate = null
      ) => {
        contents.forEach((content) => {
          if (content.id === currentDoc.id) {
            headings.push(content.heading);
            parent = parentCandidate;
            grandparent = grandparentCandidate;
          } else if (content.children && content.children.length > 0) {
            setHeadings(content.children, id, content, parentCandidate);
          }
        });
      };

      setHeadings(project.contents, currentDoc.id);

      setCurrentDocumentHeadings(headings);

      if (parent) {
        setCurrentDocParent(parent.id);
        if (parent.type === "part") {
          setCurrentPartHeading(parent.heading);
        } else if (parent.type === "chapter") {
          setCurrentChapterHeading(parent.heading);
        }
      }

      if (grandparent && grandparent.type === "part") {
        setCurrentDocGrandparent(grandparent.id);
        setCurrentPartHeading(grandparent.heading);
      }
    }
  }, [currentDoc, project.contents]);

  useEffect(() => {
    if (project && project.world) {
      const subscribeOrderedCollectionWithCatch = (
        queryData,
        setData,
        collectionName,
        sortField
      ) => {
        // Modify the query to include orderBy for sorting
        return onSnapshot(
          query(queryData, sortField),
          (snapshot) => {
            setData(
              snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
            );
          },
          (error) => {
            console.error(
              `Error in query for collection: ${collectionName}`,
              error
            );
          }
        );
      };

      const unSubElements = subscribeOrderedCollectionWithCatch(
        collection(db, "users", user.uid, "elements"),
        setElements,
        "elements",
        "name"
      );
      return () => {
        unSubElements();
      };
    }
  }, [project.world]);

  const getTotalProjectWords = () => {
    let total = 0;
    books
      .filter((b) => b.isProject)
      .forEach((b) => {
        total += parseInt(b.currentWords);
      });
    return total;
  };

  useEffect(() => {
    console.log("Sprint stats are: ", sprintStats);
  }, [sprintStats]);

  useEffect(() => {
    let unsubscribe;

    if (sprintId) {
      const sprintIdRef = ref(RTDatabase, `sprints/${sprintId}`);
      unsubscribe = onValue(sprintIdRef, (snapshot) => {
        const data = snapshot.val();
        setJoinedSprintTimer(data);
      });
    } else {
      setJoinedSprintTimer(null);
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [sprintId]);

  useEffect(() => {
    async function updateNotificationsSetting(value) {
      await updateScribiDocument({
        id: user.uid,
        notifications: value,
        doctype: "users",
        ownerId: user.uid,
      });
    }

    if (user && Notification.permission === "default") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          updateNotificationsSetting(true);
        } else {
          // Handles both 'denied' and 'default'
          updateNotificationsSetting(false);
        }
      });
    }
  }, [user]);

  function updateAndSaveStats(newTotalWords, sprintedWords, thisDate) {
    const scheduleLength = joinedSprintTimer.schedule.length;
    const currentStepIndex =
      scheduleLength > 1 ? joinedSprintTimer.currentStep - 1 : 0;

    const newStatBlock = {
      words: sprintedWords,
      started:
        Date.now() - joinedSprintTimer.schedule[currentStepIndex].duration,
      ended: Date.now(),
      averageWPM: Math.round(
        sprintedWords /
          (joinedSprintTimer.schedule[currentStepIndex].duration / 60000)
      ),
      date: thisDate.getTime(),
    };

    let newStats = { ...sprintStats };
    newStats.stats.push(newStatBlock);
    newStats.startingWords = newTotalWords;
    newStats.totalWords = newTotalWords;

    setSprintStats(newStats);

    const statsRef = ref(RTDatabase, `sprintStats/${user.uid}`);
    update(statsRef, newStats);
  }

  function updateStatsForNextSprint(totalWords) {
    let newStats = JSON.parse(JSON.stringify(sprintStats));
    newStats.startingWords = totalWords;
    newStats.totalWords = totalWords;
    setSprintStats(newStats);
    let statsRef = ref(RTDatabase, `sprintStats/${user.uid}`);
    update(statsRef, newStats);
  }

  useEffect(() => {
    if (joinedSprintTimer) {
      let newTotalWords = getTotalProjectWords();
      let thisDate = new Date();
      let sprintedWords = newTotalWords - sprintStats.startingWords;
      thisDate.setHours(0, 0, 0, 0);
      if (joinedSprintTimer.status === "complete" && sprintedWords > 0) {
        console.log(joinedSprintTimer);
        updateAndSaveStats(newTotalWords, sprintedWords, thisDate);
      }
      if (joinedSprintTimer.currentStep > 0) {
        if (
          joinedSprintTimer.schedule[joinedSprintTimer.currentStep - 1].type ===
            "sprint" &&
          sprintedWords > 0
        ) {
          updateAndSaveStats(newTotalWords, sprintedWords, thisDate);
          setNotificationModal({
            visible: true,
            title: "Sprint finished!",
            message: `You wrote ${sprintedWords} words in this sprint.`,
          })
        }
        if (
          joinedSprintTimer.schedule[joinedSprintTimer.currentStep - 1].type ===
            "shortbreak" ||
          joinedSprintTimer.schedule[joinedSprintTimer.currentStep - 1].type ===
            "longbreak"
        ) {
          updateStatsForNextSprint(newTotalWords);
        }
      }
    }
  }, [joinedSprintTimer?.status, joinedSprintTimer?.currentStep]);

  const [modal, setModal] = useState({
    visible: false,
    message: "",
    title: "",
    confirm: null,
  });

  const [formModal, setFormModal] = useState({
    component: null,
    visible: false,
  });

  const clearContext = () => {
    setUser(null);
    setPens([]);
    setNotes([]);
    setElements([]);
    setWorlds([]);
    setSeries([]);
    setBooks([]);
    setConvos([]);
    setCalendarView("month");
    setCalendarDate(new Date());
    setToday(new Date());
    setEventSeries([]);
    setShowUserMenu(false);
    setModalPosition({ x: 0, y: 0 });
    setCalendarEvents([]);
  };

  function formatTime(milliseconds) {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  }
  // FIX THIS TOMORROW ----------------------------------------------------------------------------------------------------------
  // useEffect(() => {
  //   const unsubSprintFuncs = [];
  //   console.log("Groups sprints monitored are: ", groupSprints);

  //   groupSprints.forEach((sprint) => {
  //     const groupSprintRef = ref(RTDatabase, `sprints/${sprint.id}`);
  //     const unsub = onValue(groupSprintRef, (snapshot) => {
  //       const data = snapshot.val();
  //       if (data) {
  //         console.log("Sprint data is: ", data);
  //         if(!joinedSprintTimer){
  //           setJoinedSprintTimer(data);
  //         }
  //       }
  //     });
  //     unsubSprintFuncs.push(unsub);
  //   });

  //   return () => {
  //     // Unsubscribe from all listeners when the component unmounts
  //     unsubSprintFuncs.forEach((unsub) => unsub());
  //   };
  // }, [groupSprints]);

  useEffect(() => {
    const updateToday = () => {
      const now = new Date();
      if (
        now.getDate() !== today.getDate() ||
        now.getMonth() !== today.getMonth() ||
        now.getFullYear() !== today.getFullYear()
      ) {
        setToday(now);
      }
    };

    // Update today for the first time
    updateToday();

    // Set an interval to check every hour
    const intervalId = setInterval(updateToday, 3600000); // 3600000 ms = 1 hour

    // Clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [today]);

  useEffect(() => {
    if (!location.state?.event) {
      setSidebar({ visible: false, width: 0 });
    }
  }, [location.state]);

  const saveEvent = async (events) => {
    if (Array.isArray(events)) {
      let response = await ScribiRestService.batchWrite(events);
      return response;
    } else {
      let response = await ScribiRestService.batchWrite([events]);
      return response;
    }
  };

  useEffect(() => {
    if (user) {
      const subscribeCollectionWithCatch = (query, setData, collectionName) => {
        return onSnapshot(
          query,
          (snapshot) => {
            setData(
              snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
            );
          },
          (error) => {
            console.error(
              `Error in query for collection: ${collectionName}`,
              error
            );
          }
        );
      };

      const subscribeOrderedCollectionWithCatch = (
        queryData,
        setData,
        collectionName,
        sortField
      ) => {
        // Modify the query to include orderBy for sorting
        return onSnapshot(
          query(queryData, sortField),
          (snapshot) => {
            setData(
              snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
            );
          },
          (error) => {
            console.error(
              `Error in query for collection: ${collectionName}`,
              error
            );
          }
        );
      };

      const subscribeDocumentWithCatch = (query, setData, documentName) => {
        return onSnapshot(
          query,
          (snapshot) => {
            setData({ ...snapshot.data() });
          },
          (error) => {
            console.error(
              `Error in query for document: ${documentName}`,
              error
            );
          }
        );
      };

      const unSubConvos = subscribeCollectionWithCatch(
        collection(db, "users", user.uid, "convos"),
        setConvos,
        "convos"
      );
      const unSubNotes = subscribeCollectionWithCatch(
        collection(db, "users", user.uid, "notes"),
        setNotes,
        "notes"
      );

      const unSubCalendarEvents = subscribeOrderedCollectionWithCatch(
        collection(db, "users", user.uid, "calendarEvent"),
        setCalendarEvents,
        "calendarEvent",
        orderBy("start", "asc")
      );

      const timerQ = query(
        collection(db, "timers"),
        or(
          where("ownerId", "==", user.uid),
          where("group", "array-contains", user.uid)
        )
      );

      // const groupTimerQ = query(
      //   collection(db, "timers"),

      // );

      const unsubTimer = onSnapshot(timerQ, (querySnapshot) => {
        let updatedGroupSprints = [...groupSprints];

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          const existingIndex = updatedGroupSprints.findIndex(
            (sprint) => sprint.id === data.id
          );

          if (existingIndex !== -1) {
            // Update existing sprint
            updatedGroupSprints[existingIndex] = data;
          } else {
            // Add new sprint
            updatedGroupSprints.push(data);
          }
        });

        setGroupSprints(updatedGroupSprints);
      });

      const sprintStatsCallback = (snapshot) => {
        let statsData = snapshot.val();
        if (statsData) {
          statsData = { ...statsData }; // Make a shallow copy
          if (!statsData.stats) {
            statsData.stats = [];
          }
          setSprintStats(statsData);
        } else {
          setSprintStats(null);
        }
      };

      const sprintStatsRef = ref(RTDatabase, `sprintStats/${user.uid}`);

      onValue(sprintStatsRef, sprintStatsCallback);

      const sprintTimerCallback = (snapshot) => {
        const sprint = snapshot.val();
        if (sprint) {
          if (sprint.sprinting) {
            setSprintId(sprint.id);
          }
        } else {
          setSprintId(null);
        }
      };

      const sprintWatcherRef = ref(RTDatabase, `isSprinting/${user.uid}`);

      onValue(sprintWatcherRef, sprintTimerCallback);

      return () => {
        unSubConvos();
        unSubNotes();
        unSubCalendarEvents();
        unsubTimer();
        // unsubGroupTimer();
      };
    }
  }, [user]);

  const deletePen = async (pen) => {
    setModal({
      visible: true,
      message: "Are you sure you want to delete this pen?",
      title: "Confirm",
      confirm: () => {
        setModal({ visible: false });
        deletePenConfirmed(pen);
      },
      cancel: () => {
        setModal({ visible: false });
      },
    });
  };

  const deletePenConfirmed = async (pen) => {
    //need to set up for batch deleting. Might have got it, wasn't adding the pen.
    let docs = [pen, ...collectPenDocs(pen.id)];
    let res = await batchDeleteScribiDocuments(docs);
    // let res = await deleteScribiDocument(pen);
    if (res.isDeleted) {
      setModal({
        visible: true,
        message: "Pen deleted successfully.",
        title: "Success",
        confirm: () => {
          navigate("/");
          setModal({ visible: false });
        },
      });
    } else {
      setModal({
        visible: true,
        message: "Error deleting pen.",
        title: "Error",
        confirm: () => {
          setModal({ visible: false });
        },
      });
    }
  };

  const collectPenDocs = (penId) => {
    let docs = [];
    let noteDocs = [];
    let docMap = new Map(); // Map to track documents that can have notes

    // Helper to add documents and update docMap
    const addDoc = (doc) => {
      docs.push(doc);
      docMap.set(doc.id, doc);
    };

    // Single-pass scan for worlds, books, and series
    for (const world of worlds) {
      if (world.pen === penId) {
        addDoc(world);
      }
    }

    for (const book of books) {
      if (book.pen === penId) {
        addDoc(book);
        if (book.standalone && docMap.has(book.world)) {
          addDoc(docMap.get(book.world));
        }
      }
    }

    for (const seriesItem of series) {
      docs.push({
        id: seriesItem.id,
        doctype: "series",
        ownerId: seriesItem.ownerId,
      });
      docMap[seriesItem.id] = true;
    }

    // Single-pass scan for elements
    for (const element of elements) {
      if (docMap.has(element.world)) {
        addDoc(element);
      }
    }

    // Single-pass scan for notes
    for (const note of notes) {
      if (docMap.has(note.parent)) {
        noteDocs.push(note);
      }
    }

    // Combine docs and noteDocs
    docs.push(...noteDocs);

    return docs;
  };

  const deleteWorld = async (world) => {};

  const deleteSeries = async (series) => {};

  const deleteBook = async (book) => {};

  const addDocument = async (id) => {
    let newDocId = uuid();
    let newDocument = {
      id: newDocId,
      ownerId: user.uid,
      doctype: "documents",
      content: {
        type: "doc",
        content: [
          {
            type: "paragraph",
          },
        ],
      },
      words: 0,
      editors: [],
      viewers: [],
      notes: [],
    };
    const newContentObject = {
      type: "document",
      versions: [
        {
          id: newDocId,
          description: "Original",
          version: 1,
        },
      ],
      words: 0,
      heading: "",
      subheading: "",
      id: newDocId,
      outline: {
        type: "doc",
        content: [
          {
            type: "paragraph",
          },
        ],
      },
      elements: [],
      notes: [],
    };
    let newBook = JSON.parse(JSON.stringify(books.find((b) => b.id === id)));
    newBook.contents.push(newContentObject);
    try {
      await createScribiDocument(newDocument);
      await updateScribiDocument(newBook);
      let newProjectNavState = JSON.parse(localStorage.getItem(id));
      newProjectNavState.selected = newDocId;
      setProjectNavState(newProjectNavState);
    } catch (error) {
      console.log(error);
      setModal({
        visible: true,
        message: "Error creating document.",
        title: "Error",
        confirm: () => {
          setModal({ visible: false });
        },
      });
    }
  };

  const addChapter = async (id) => {
    let newContentObject = {
      id: uuid(),
      type: "chapter",
      heading: "",
      subheading: "",
      children: [],
      outline: {
        type: "doc",
        content: [
          {
            type: "paragraph",
          },
        ],
      },
    };

    let newBook = JSON.parse(JSON.stringify(books.find((b) => b.id === id)));
    newBook.contents.push(newContentObject);
    try {
      await updateScribiDocument(newBook);
    } catch (error) {
      console.log(error);
      setModal({
        visible: true,
        message: `Error creating chapter: ${error.message}`,
        title: "Error",
        confirm: () => {
          setModal({ visible: false });
        },
      });
    }
  };

  const addPart = async (id) => {
    let newContentObject = {
      id: uuid(),
      type: "part",
      heading: "",
      subheading: "",
      children: [],
      outline: {
        type: "doc",
        content: [
          {
            type: "paragraph",
          },
        ],
      },
    };

    let newBook = JSON.parse(JSON.stringify(books.find((b) => b.id === id)));
    newBook.contents.push(newContentObject);
    try {
      await updateScribiDocument(newBook);
    } catch (error) {
      console.log(error);
      setModal({
        visible: true,
        message: `Error creating part: ${error.message}`,
        title: "Error",
        confirm: () => {
          setModal({ visible: false });
        },
      });
    }
  };

  const deleteContentPart = async (id) => {
    let newProject = JSON.parse(JSON.stringify(project));

    const removeItemByIdWithMap = (id, contents) => {
      let newContents = [];
      for (const content of contents) {
        if (content.id !== id) {
          if (content.children) {
            content.children = removeItemByIdWithMap(id, content.children);
          }
          newContents.push(content);
        }
      }
      return newContents;
    };

    newProject.contents = removeItemByIdWithMap(id, newProject.contents);

    try {
      await updateScribiDocument(newProject);
    } catch (error) {
      console.log(error);
      setModal({
        visible: true,
        message: `Error deleting part: ${error.message}`,
        title: "Error",
        confirm: () => {
          setModal({ visible: false });
        },
      });
    }
  };

  return (
    <Scribi.Provider
      value={{
        user,
        setUser,
        pens,
        worlds,
        series,
        books,
        setBooks,
        convos,
        calendarView,
        setCalendarView,
        calendarDate,
        setCalendarDate,
        today,
        eventSeries,
        setEventSeries,
        navigate,
        showUserMenu,
        setShowUserMenu,
        modalPosition,
        setModalPosition,
        saveEvent,
        calendarEvents,
        setCalendarEvents,
        clearContext,
        modal,
        setModal,
        elements,
        setElements,
        deletePen,
        notes,
        setNotes,
        sidebar,
        setSidebar,
        location,
        deleteWorld,
        deleteSeries,
        deleteBook,
        projectNavState,
        setProjectNavState,
        searchParams,
        project,
        setProject,
        id,
        flattenedProjectContents,
        setFlattenedProjectContents,
        contextMenu,
        setContextMenu,
        addDocument,
        addChapter,
        addPart,
        timer,
        setTimer,
        groupSprints,
        setGroupSprints,
        sprintStats,
        setSprintStats,
        formModal,
        setFormModal,
        joinedSprintTimer,
        setJoinedSprintTimer,
        dragging,
        setDragging,
        currentDoc,
        setCurrentDoc,
        currentChapterHeading,
        setCurrentChapterHeading,
        currentPartHeading,
        setCurrentPartHeading,
        currentDocumentHeadings,
        setCurrentDocumentHeadings,
        currentDocParent,
        setCurrentDocParent,
        currentDocGrandparent,
        setCurrentDocGrandparent,
        deleteContentPart,
        note,
        setNote,
      }}
    >
      {children}
    </Scribi.Provider>
  );
};

export default ScribiProvider;
