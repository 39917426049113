import React from "react";

const MenuContext = React.createContext();

const MenuContextProvider = ({ children }) => {
    const [menu, setMenu] = React.useState(null);

    return (
        <MenuContext.Provider
            value={{
                menu,
                setMenu,
            }}
        >
            {children}
        </MenuContext.Provider>
    );
}

export { MenuContext, MenuContextProvider };