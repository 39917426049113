import React from "react";

const ProjectContext = React.createContext();

const ProjectContextProvider = ({ children }) => {
    const [project, setProject] = React.useState([]);
    const [elements, setElements] = React.useState([]);
    const [totalWords, setTotalWords] = React.useState(0);

    const contextValue = React.useMemo(() => {
        return {
            project,
            setProject,
            elements,
            setElements,
            totalWords,
            setTotalWords,
        };
    }
    , [project, setProject, elements, setElements, totalWords, setTotalWords]);

    return (
        <ProjectContext.Provider
            value={contextValue}
        >
            {children}
        </ProjectContext.Provider>
    );
}

export { ProjectContext, ProjectContextProvider };