import React, { useMemo } from "react";

const SocialContext = React.createContext();

const SocialContextProvider = ({ children }) => {
    const [chats, setChats] = React.useState([]);
    const [friends, setFriends] = React.useState([]);
    const [groups, setGroups] = React.useState([]);
    const [friendRequests, setFriendRequests] = React.useState([]);
    const [groupRequests, setGroupRequests] = React.useState([]);
    const [groupInvites, setGroupInvites] = React.useState([]);
    const [posts, setPosts] = React.useState([]);

    const contextValue = useMemo(() => {
        return {
            chats,
            setChats,
            friends,
            setFriends,
            groups,
            setGroups,
            friendRequests,
            setFriendRequests,
            groupRequests,
            setGroupRequests,
            groupInvites,
            setGroupInvites,
            posts,
            setPosts,
        };
    }
    , [chats, setChats, friends, setFriends, groups, setGroups, friendRequests, setFriendRequests, groupRequests, setGroupRequests, groupInvites, setGroupInvites, posts, setPosts]);

    

    return (
        <SocialContext.Provider
            value={contextValue}
        >
            {children}
        </SocialContext.Provider>
    );
}

export { SocialContext, SocialContextProvider };